/* eslint-disable */

'use strict';

/*-------------------------------------------------
    Source of this template in root directory
    environment/config.template.js
    this is only example which you may define any structure
---------------------------------------------------*/
/**
 * app configuration from environment
 * @typedef {Object} config
 * @property {Number}       timestamp
 * @property {String}       version
 * @property {Boolean}      production
 * @property {String}       baseUrl
 * @property {String}       apiPath
 * @property {String}       serviceUrl
 * @property {String}       websiteUrl
 */
export default {
    "timestamp": 1728418030835,
    "version": "1.0.1",
    "DEBUG": false,
    "production": true,
    "clientTimeFormat": "D MMMM YYYY",
    "serviceUrl": "https://salvation-army-api.app.risk-q.com",
    "selfUrl": "https://salvation-army-admin.app.risk-q.com/",
    "websiteUrl": "https://salvation-army.app.risk-q.com/",
    "apiPath": "/api",
    "base": "com.vrisk:21827392bacff",
    "MICROSOFT_CLIENT_ID": "9daee397-7e2f-43c8-974c-6de77d154985"
}
